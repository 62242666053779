import * as React from "react"
import styled from "styled-components"
import { Header4 } from "./Typography"

const SectionHeaderContainer = styled.div`
  display: inline-grid;
  grid-auto-flow: column;
  gap: 10px;
  align-items: center;
  justify-items: start;
  justify-self: start;
  color: var(--base070);
`

const Line = styled.div`
  height: 1px;
  width: 32px;
  background: var(--primary100);
`

const SectionHeader = props => {
  return (
    <SectionHeaderContainer {...props}>
      <Line />
      <Header4>{props.children}</Header4>
    </SectionHeaderContainer>
  )
}

export default SectionHeader

import React from "react"
import Banner from "../components/Banner"
import Button from "../components/Button"
import { graphql } from "gatsby"

import nsl_in_tensorflow from "../images/pdf/nsl_in_tensorflow.pdf"
import tensorflow_background_in_machine_learning from "../images/pdf/tensorflow_background_in_machine_learning.pdf"
import predictive_analytics_using_extreme_learning_machine from "../images/pdf/predictive_analytics_using_extreme_learning_machine.pdf"
import recommendations_for_all_solving_thousands_of_recommendation_problems_daily from "../images/pdf/recommendations_for_all_solving_thousands_of_recommendation_problems_daily.pdf"
import deep_neural_networks_for_youtube_recommendations from "../images/pdf/deep_neural_networks_for_youtube_recommendations.pdf"
import email_category_prediction from "../images/pdf/email_category_prediction.pdf"
import predictive_analytics_in_information_systems_research from "../images/pdf/predictive_analytics_in_information_systems_research.pdf"
import predicting_bounce_rates_in_sponsored_search_advertisements from "../images/pdf/predicting_bounce_rates_in_sponsored_search_advertisements.pdf"
import a_survey_of_predictive_analytics_using_big_data_with_data_mining from "../images/pdf/a_survey_of_predictive_analytics_using_big_data_with_data_mining.pdf"
/* import ad_quality_on_tv_predicting_television_audience_retention from "../images/pdf/ad_quality_on_tv_predicting_television_audience_retention.pdf"
 */ import machine_learning_algorithms_a_background_artifact from "../images/pdf/machine_learning_algorithms_a_background_artifact.pdf"
import tensorflow_large_scale_machine_learning_on_heterogeneous_distributed_systems from "../images/pdf/tensorflow_large-scale_machine_learning_on_heterogeneous_distributed_systems.pdf"
import SentimentAnalysis from "../images/pdf/SentimentAnalysis.pdf"
import DonorRetentionRedCross from "../images/pdf/DonorRetentionRedCross.pdf"
import BusinessImplicationsMachineLearning from "../images/pdf/BusinessImplicationsMachineLearning.pdf"

import Layout from "../components/layout"
import {
  Body,
  Bold,
  Caption,
  Header1,
  Header1Accent,
  Header2,
  Header3,
  Header5,
} from "../components/Typography"
import Section from "../components/Section"
import MainPoint from "../components/MainPoint"
import SectionHeader from "../components/SectionHeader"

import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import opengraph from "../images/opengraph.png"

const ResourceCard = styled.a`
  display: grid;
  gap: 16px;
  grid-auto-flow: row;
  align-items: start;
  align-content: start;
`

const ResourceContainer = styled.div`
  display: grid;
  gap: 32px;
  padding-top: 32px;
  grid-template-columns: repeat(4, 1fr);

  @media screen and (max-width: 1000px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`

const StyledLink = styled.a`
  color: var(--primary100);
  text-decoration: none;
  font-weight: 500;

  :hover {
    text-decoration: underline;
  }
`

function ArtificialIntelligencePage(props) {
  const resources = [
    {
      name: "Efficient Learning of Donor Retention Strategies for The American Red Cross",
      url: DonorRetentionRedCross,
      tag: "Miscellaneous",
      image: props.data.ai.nodes[12],
    },
    {
      name: "NSL in TensorFlow",
      url: nsl_in_tensorflow,
      tag: "Machine Learning",
      image: props.data.ai.nodes[0],
    },
    {
      name: "A System for Large Scale Machine Learning",
      url: tensorflow_background_in_machine_learning,
      tag: "Machine Learning",
      image: props.data.ai.nodes[1],
    },
    {
      name: "Predictive Analytics Using Extreme Learning Machine",
      url: predictive_analytics_using_extreme_learning_machine,
      tag: "Machine Learning",
      image: props.data.ai.nodes[2],
    },
    {
      name: "Solving Recommendation Problems Daily",
      url: recommendations_for_all_solving_thousands_of_recommendation_problems_daily,
      tag: "Machine Learning",
      image: props.data.ai.nodes[3],
    },
    {
      name: "Deep Neural Networks for YouTube Recommendations",
      url: deep_neural_networks_for_youtube_recommendations,
      tag: "Predictive Analytics",
      image: props.data.ai.nodes[4],
    },
    {
      name: "Email Category Prediction Using MLP & LSTM",
      url: email_category_prediction,
      tag: "Predictive Analytics",
      image: props.data.ai.nodes[5],
    },
    {
      name: "Predictive Analytics in Information Systems Research",
      url: predictive_analytics_in_information_systems_research,
      tag: "Predictive Analytics",
      image: props.data.ai.nodes[6],
    } /* 
    {
      name: "Predicting Bounce Rates in Sponsored Search Ads",
      url: predicting_bounce_rates_in_sponsored_search_advertisements,
      tag: "Predictive Analytics",
      image: props.data.ai.nodes[7],
    }, */,
    {
      name: "Predictive Analytics Using Big Data with Data Mining",
      url: a_survey_of_predictive_analytics_using_big_data_with_data_mining,
      tag: "Predictive Analytics",
      image: props.data.ai.nodes[8],
    } /* 
    {
      name: "Predicting Audience Retention",
      url: ad_quality_on_tv_predicting_television_audience_retention,
      tag: "Predictive Analytics",
      image: "null",
    }, */,
    {
      name: "Machine Learning Algorithms",
      url: machine_learning_algorithms_a_background_artifact,
      tag: "Machine Learning",
      image: props.data.ai.nodes[10],
    },
    {
      name: "Large Scale Machine Learning on Heterogeneous Distributed Systems",
      url: tensorflow_large_scale_machine_learning_on_heterogeneous_distributed_systems,
      tag: "Machine Learning",
      image: props.data.ai.nodes[11],
    },
    {
      name: "Sentiment Classification using Machine Learning Techniques",
      url: SentimentAnalysis,
      tag: "Machine Learning",
      image: props.data.ai.nodes[13],
    },
  ]
  return (
    <Layout pageTitle="AI With a Human Touch" image={opengraph}>
      <Banner
        text={[
          <Header1>
            AI With a <Header1Accent>Human</Header1Accent> Touch&trade;
          </Header1>,
          <Header3>
            Further your advancement without replacing that human touch.
          </Header3>,
        ]}
        buttons={[
          <Button primary url="/demo">
            Request Demo
          </Button>,
        ]}
        image={props.data.banner}
      />
      <Section id="future">
        <MainPoint
          reversed
          text={[
            <Header2>The future of advancement</Header2>,
            <Body>
              Your university competes with over <Bold>1.7 million</Bold> worthy
              causes for your alumni’s charitable dollars. Moreover, megatrends
              such as declining participation rates, generational changes in
              attitudes towards giving, and tax code changes have a materially
              negative impact on fundraising. In an increasingly competitive and
              challenging fundraising environment, maintaining the status quo is
              not an option.
            </Body>,
          ]}
        />
        <MainPoint
          text={[
            <Header5>Bringing AI Into Advancement</Header5>,
            <Body>
              As AI penetrates all industries, Advancement will be no exception.
              The top performing nonprofits and Advancement departments are
              already using AI to improve their returns on fundraising efforts.
              Now is the time to join them.
            </Body>,
            <Header5>Why We Use AI</Header5>,
            <Body>
              CueBack is at the forefront of applying AI to Advancement to
              maximize giving, minimize costs, and increase predictability. At
              its heart, Advancement faces similar challenges to any business.
              For this reason, Advancement departments can benefit from many of
              the{" "}
              <StyledLink
                href={BusinessImplicationsMachineLearning}
                target="_blank"
              >
                new and exciting technologies
              </StyledLink>{" "}
              used by other industries.
            </Body>,
          ]}
          image={props.data.intro}
        />
      </Section>
      <Section id="predictive-analytics">
        <MainPoint
          reversed
          text={[
            <Header2>A new tool in the toolbox: predictive analytics</Header2>,
            <Body>
              Predictive analytics uncovers patterns, trends, and associations
              hidden within all types of data to help predict future outcomes,
              solve problems and optimize decision-making. Predictive analytics
              can help distinguish low- vs. high-touch donors to allow Major,
              Principal, and Planned Gift Officers to focus on high-value
              donors. AI provides the insights required to help gift officers
              cultivate the deeply personal relationships that generate the
              largest financial returns for the university.
            </Body>,
            <Body>
              CueBack’s predictive analytics helps scale universities unlock a
              360-degree view of their donors to:
            </Body>,
            <Body>
              <ul>
                <li>
                  Identify prospective donors and know who is likely to give,
                  how much, and for what cause(s)
                </li>
                <br />
                <li>
                  Attract high-value donors and increase contribution amounts
                </li>
                <br />
                <li>
                  Reduce costs by optimizing resources, in turn improving
                  efficiency of the overall Advancement operation
                </li>
              </ul>
            </Body>,
          ]}
          image={props.data.predictive}
        />
      </Section>
      <div style={{ background: "var(--base100)", color: "var(--base000)" }}>
        <Section
          style={{ background: "var(--base100)", color: "var(--base000)" }}
          id="process"
        >
          <SectionHeader style={{ color: "var(--base010)" }}>
            CueBack's AI Process
          </SectionHeader>
          <MainPoint
            text={[
              <Header2>Make sense of your data</Header2>,
              <Body>
                Align your existing donor information from your organization’s
                databases, spreadsheets, and social media with a single
                solution. CueBack merges your existing information with
                quantitative and qualitative data{" "}
                <Bold>generated by your alumni</Bold> on our engagement and
                event management applications. This gives you the ability to
                have a complete view of your alumni base as a whole, while also
                being able to drill down by each individual alum.
              </Body>,
            ]}
            image={props.data.fourSteps1}
          />
          <MainPoint
            reversed
            text={[
              <Header2>Predict behavior</Header2>,
              <Body>
                CueBack's predictive model uses quantitative and qualitative
                data to segment and score prospects in order to predict the
                likelihood, size, and timing of donations.
              </Body>,
              <Body>
                Sentiment analysis is used to calculate each alum's Willingness
                Score&trade;, and is used in conjunction with Connections
                Score&trade;, and Discovery Readiness Score&trade; to better
                understand the giving opportunities of each prospect.
              </Body>,
            ]}
            image={props.data.fourSteps2}
          />
          <MainPoint
            text={[
              <Header2>Convert prospects to donors</Header2>,
              <Body>
                Develop cultivation strategies for your prospects and convert
                them into donors. The personal touch of a gift officer is
                critical for building the relationships that underpin
                transformational giving. CueBack’s AI engine provides them with
                deep insights about their prospects to help build rapport and
                trust that lasts a lifetime.
              </Body>,
            ]}
            image={props.data.fourSteps3}
          />
          <MainPoint
            reversed
            text={[
              <Header2>Re-optimize models</Header2>,
              <Body>
                CueBack’s AI Engine is constantly learning. It continuously uses
                real-time feedback from ongoing advancement and engagement
                activities to improve its predictions and insights over time.
              </Body>,
            ]}
            image={props.data.fourSteps4}
          />
        </Section>
      </div>
      <Section id="resources">
        <SectionHeader>Resources</SectionHeader>
        <ResourceContainer>
          {resources.map(resource => (
            <ResourceCard href={resource.url} target="_blank">
              <GatsbyImage image={getImage(resource.image)} />
              <Caption
                style={{
                  padding: "5px 10px",
                  background: "var(--primary015)",
                  justifySelf: "start",
                  borderRadius: 2,
                }}
              >
                {resource.tag}
              </Caption>
              <Header3>{resource.name}</Header3>
            </ResourceCard>
          ))}
        </ResourceContainer>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query {
    lightImage: allFile(
      filter: { relativePath: { eq: "index-bg-light.jpeg" } }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, aspectRatio: 1)
        }
      }
    }
    intro: allFile(
      filter: {
        relativePath: {
          eq: "artificial-intelligence/cb_linkedin_aiMichelangelo.png"
        }
      }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: CONSTRAINED
            width: 800
            aspectRatio: 1
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
    }
    fourSteps1: allFile(
      filter: { relativePath: { eq: "artificial-intelligence/4-steps-1.png" } }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: CONSTRAINED
            width: 800
            aspectRatio: 1
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
    }
    fourSteps2: allFile(
      filter: { relativePath: { eq: "artificial-intelligence/4-steps-2.png" } }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: CONSTRAINED
            width: 800
            aspectRatio: 1
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
    }
    fourSteps3: allFile(
      filter: { relativePath: { eq: "artificial-intelligence/4-steps-3.png" } }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: CONSTRAINED
            width: 800
            aspectRatio: 1
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
    }
    fourSteps4: allFile(
      filter: { relativePath: { eq: "artificial-intelligence/4-steps-4.png" } }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: CONSTRAINED
            width: 800
            aspectRatio: 1
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
    }
    predictive: allFile(
      filter: {
        relativePath: {
          eq: "artificial-intelligence/markus-winkler-IrRbSND5EUc-unsplash.jpg"
        }
      }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: CONSTRAINED
            width: 800
            aspectRatio: 1
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
    }
    banner: allFile(
      filter: {
        relativePath: {
          eq: "artificial-intelligence/michael-dziedzic-nbW-kaz2BlE-unsplash.jpg"
        }
      }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: CONSTRAINED, width: 800)
        }
      }
    }
    resourceImage: allFile(
      filter: { relativePath: { eq: "index-bg-dark.jpeg" } }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: CONSTRAINED
            width: 800
            aspectRatio: 1.9589041096
          )
        }
      }
    }
    ai: allFile(
      filter: { relativePath: { regex: "/artificial-intelligence/resources/" } }
      sort: { fields: name }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: CONSTRAINED
            width: 800
            aspectRatio: 1.9589041096
          )
        }
      }
    }
  }
`

export default ArtificialIntelligencePage

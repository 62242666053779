import * as React from "react"

import { GatsbyImage } from "gatsby-plugin-image"

import styled from "styled-components"
import Section from "./Section"

const BannerWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-size: cover;
  background-position: center;
  position: relative;

  background: ${props => (props.imageExists ? "none" : "var(--primary015)")};
`

const BackgroundImage = styled.div`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
`

const DarkBackgroundImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  overflow: hidden;

  @media screen and (prefers-color-scheme: dark) {
    display: block;
  }
`

const BannerContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 16px;
  justify-items: start;
  width: 50%;
  position: relative;
  z-index: 1;
  padding: 24px 32px;
  overflow: hidden;
  backdrop-filter: blur(16px);

  @media screen and (max-width: 800px) {
    width: 100%;
  }

  h1 {
    color: var(--base100);

    span {
      color: var(--primary100);
    }
  }

  h2,
  h3 {
    color: var(--base070);
  }

  :before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--base000);
    opacity: 0.8;
    z-index: -1;
    border-radius: 2px;
  }
`

const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  margin-top: 16px;

  > * {
    margin-top: 16px;
  }

  > * :not(:last-child) {
    margin-right: 16px;
  }
`

function Banner(props) {
  let darkImage = ""
  let image = ""

  let imageExists = false

  if (props.image) {
    image = (
      <BackgroundImage>
        <GatsbyImage
          image={props.image.nodes[0].childImageSharp.gatsbyImageData}
          objectFit="cover"
          style={{ height: "100%", width: "100%" }}
        />
      </BackgroundImage>
    )
    imageExists = true

    if (props.darkImage) {
      darkImage = (
        <DarkBackgroundImage>
          <GatsbyImage
            image={props.darkImage.nodes[0].childImageSharp.gatsbyImageData}
            objectFit="cover"
            style={{ height: "100%", width: "100%" }}
          />
        </DarkBackgroundImage>
      )
    }
  }

  return (
    <BannerWrapper imageExists={imageExists}>
      <Section>
        {image}
        {darkImage}
        <BannerContainer>
          {props.text.map(item => item)}
          {props.buttons ? (
            <ButtonRow>{props.buttons.map(button => button)}</ButtonRow>
          ) : (
            ""
          )}
        </BannerContainer>
      </Section>
    </BannerWrapper>
  )
}

export default Banner
